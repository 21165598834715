<template>
  <div class="index-box">
    <div class="img-mt">
      <div class="bg-font">
        <img src="../assets/images/qiucai.png" />
        <p v-if="language == 'zh-CN'">
          球彩AI，一款专为体育迷打造的智能预测应用，旨在通过先进的数据分析和人工智能技术，让你的体育赛事预测之旅更加精准、有趣。我们利用复杂的算法分析海量数据，结合历史表现和实时信息，为你提供最准确的比赛结果预测。无论你是足球、篮球还是其他体育项目的狂热爱好者，球彩AI都能成为你不可多得的得力助手。
        </p>
        <p v-else style="letter-spacing: 0rem;">QC AI, an intelligent prediction app tailored for sports fans, aims to
          make your sports event prediction journey more precise and enjoyable through advanced data analysis and
          artificial intelligence technology. We utilize complex algorithms to analyze massive amounts of data,
          combining historical performance with real-time information to provide you with the most accurate match result
          predictions. Whether you are a passionate fan of football, basketball, or other sports, QC AI can become your
          invaluable and indispensable assistant.</p>
        <a href="javascript:;" @click="getDeviceType()">下载球彩AI APP</a>
      </div>
      <div class="img-right">
      </div>
    </div>

  </div>
</template>

<script>
import { downloadAPK } from "@/api/api";
export default {
  data() {
    return {
      file: '',
      version: '',
      date: ''
    };
  },
  created() { },
  methods: {
    getDeviceType() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // if (/android/i.test(userAgent)) {
      //   downloadAPK({}).then((response) => { 
      //     const processedText = response.data;
      //     window.location.href = 'https://download.huiyi-service.com/' + processedText.file
      //   });
      // } else if (/Mac|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      //   console.log("iPhone");
      //   window.location.href = 'https://itunes.apple.com/app/id6670332968'
      // }
      // else if (userAgent.indexOf("Windows NT 10") > -1 || userAgent.indexOf("Windows 10")) {
      //   downloadAPK({}).then((response) => {
      //     const processedText = response.data;
      //     window.location.href = 'https://download.huiyi-service.com/' + processedText.file
      //   });
      // } else {
      //   console.log("123344")
      // }

      if (/Mac|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        console.log("iPhone");
        window.location.href = 'https://itunes.apple.com/app/id6670332968'
      } else {
        console.log("123344")
      }

    },
  },
};
</script>

<style scoped>
.index-box {
  background-image: url("../assets/images/index-bg.png");
  background-color: #5b8cad;
  position: relative;
  height: 80rem;
  background-repeat: no-repeat;
  margin-top: 10.5rem;
  position: relative;
  background-size: cover;
  /* 背景图片覆盖整个容器 */
  background-position: center;
  /* 背景图片居中 */
}

.bg-font {
  width: 50rem;
  text-align: left;
}

.bg-font img {
  width: 40rem;
}

.img-mt {
  width: 130rem;
  margin: 0px auto;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.img-right {
  width: 77rem;
  height: 63rem;
  background: url("../assets/images/bjtu.png") no-repeat;
  border-radius: 2rem;
  position: relative;
  background-size: cover;
  /* 背景图片覆盖整个容器 */
  background-position: center;
}

.img-right img {
  width: 20rem;
  height: 20rem;
  position: absolute;
  top: -6rem;
  right: 20rem;
  z-index: 2;
}

.bg-font h3 {
  font-size: 5.2rem;
  color: #fff;
  font-weight: bold;
}

.bg-font p {
  width: 43rem;
  font-size: 2rem;
  color: #ffffff;
  margin-top: 4rem;
  line-height: 3rem;
  letter-spacing: 0.5rem;

}

.bg-font a {
  display: block;
  width: 20rem;
  padding: 1.5rem 0px;
  background: #0486ff;
  border-radius: 0.8rem;
  color: #fff;
  text-align: center;
  margin-top: 3rem;
}
</style>